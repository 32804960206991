body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  box-sizing: border-box;
  overflow: scroll;
  background-color: #f8f8f8;
}

::-webkit-scrollbar {
  display: none;
}

.ui.grid {
  margin: 0;
}

.ui.grid > .column:not(.row) {
  padding: 0;
}

.loginForm > *:not(:first-child) {
  margin-top: 1rem;
}

.FormError {
  color: #ec4730;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 0.25rem !important;
}

.searchWrapper {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.searchWrapper > * {
  margin-left: 1rem !important;
}

.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 1rem;
}

.modalForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modalForm > *:not(:first-child) {
  margin-top: 1rem;
}

.postsWrapper {
  padding: 1rem;
}

.postsWrapper > *:not(:first-child) {
  margin-top: 1rem;
}

.textButton {
  padding: 0 !important;
  background-color: transparent !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #e6007a !important;
  font-weight: normal !important;
}
